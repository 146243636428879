.ol-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.ol-attribution.ol-logo-only {
  bottom: 15px !important;
  left: 15px !important;
  width: 22px !important;
  height: 22px !important;
}

.map-control-button {
  display: inline-block !important;
  display: none !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 60px !important;
  background-color: white !important;
  margin: 10px 10px 10px 0 !important;
  padding: 2px 6px 7px 6px !important;
  box-sizing: border-box !important;
  border-radius: 99px !important;
  text-align: center !important;
  font-size: 30px !important;
  cursor: pointer;
  color: black !important;
  &:hover,
  &:active,
  &:focus {
    background-color: white;
    outline: none;
  }
}

.ol-zoom {
  display: none;
}
