.snackbar-buttons {
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 100%;
  max-width: 200px;
  background-color: #f5f5f5;
  padding: 16px 25px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0, 0, 0, 1.29);
  font-size: 0;
  bottom: 30px;
  text-align: center;

  .icon-confirm {
    width: 60px;
    margin-right: 20px;
    cursor: pointer;
  }
  .icon-cancel {
    width: 60px;
    cursor: pointer;
  }
}
