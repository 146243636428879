html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Karla", sans-serif;

  * {
    box-sizing: border-box;
  }
}

div.error,
div.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
  }

  p {
    text-align: center;
  }
}
