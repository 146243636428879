.tooltipbase {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.tooltip-static {
    background-color: #ffcc33;
    color: black;
    border: 1px solid white;

    &:before {
        border-top-color: #ffcc33;
    }
}